<template>
    <div  class="navmb">
        <div  class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon bgshadow">
                <Back />
            </el-icon>
            <span  class="">搜索</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-searchl">
        <div class="search">
            <el-input
            v-model="input3"
            style="max-width: 804px;background-color: #F7F7F7;"
            value="数据"
            class="input-with-select"
            size="large"
            >
            </el-input>
            <el-button color="#FF6A00" size="large">
                <el-icon :size="12" color="#fff" class="icon">
                    <Search />
                </el-icon>
            </el-button>
        </div>
        <div class="history">
            <div class="ll">搜索历史</div>
            <div class="rr">
                <span>数据类型</span>
                <span>数据迁移</span>
                <span>服务器服务丢失</span>
            </div>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-resultl">
        <div class="tips">搜索“数据”的结果，共找到108条</div>
        <ul class="ul-listl1 fix">
            <li v-for="(item,index) in listl1" :key="index" @click="OnClick(index)" :class="act == index ? 'on':''">
                <div class="con">{{item}}</div>
            </li>
        </ul>
        <div class="result" v-if="act == 0">
            <div class="item">
                <div class="m-titl1">
                    <a href="" class="more">查看更多 ></a>
                    <div class="tit">知识库文档（99+）</div>
                </div>
                <ul class="ul-listl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">Vastbase G100 2.2 Build 13发布<span>数据</span>说明</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">Vastbase G100在银河麒麟桌面操作系统V10(SP1)上<span>数据</span>安装报错</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">Vastbase G100 2.2 Build 13发布<span>数据</span>说明</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">Vastbase G100在银河麒麟桌面操作系统V10(SP1)上<span>数据</span>安装报错</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="item">
                <div class="m-titl1">
                    <a href="" class="more">查看更多 ></a>
                    <div class="tit">服务请求（10）</div>
                </div>
                <ul class="ul-listl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">监控主机上的agent目录<span>数据</span>是否会自动清理</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="item">
                <div class="m-titl1">
                    <a href="" class="more">查看更多 ></a>
                    <div class="tit">海量资讯（19）</div>
                </div>
                <ul class="ul-listl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群数据升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增数据Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群数据升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增数据Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群数据升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增数据Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群数据升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="result" v-if="act == 1">
            <div class="item">
                <div class="m-titl1 styl2">
                    <!-- <a href="" class="more">查看更多 ></a> -->
                    <div class="tit">知识库文档（99+）</div>
                </div>
                <ul class="ul-listl3">
                    <li>
                        <a href="" class="con">
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">Vastbase G100 2.2 Build 13发布<span>数据</span>说明</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">Vastbase G100在银河麒麟桌面操作系统V10(SP1)上<span>数据</span>安装报错</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">Vastbase G100 2.2 Build 13发布<span>数据</span>说明</div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">Vastbase G100在银河麒麟桌面操作系统V10(SP1)上<span>数据</span>安装报错</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="tit">新增Vastbase<span>数据</span>库请求结构</div>
                            <div class="desc">
                                <p>API 支持就近地域接入，本产品就近地域接入域名为 ba.tencentcloudapi.com ，也支持指定地域域名访问，例如广州地域的域guangzhou.tencentcloudapi.com 。推荐使用就近地域接入域名。根据调用接口时客户端所在位置，会自动解析到最近的某个具体地内存优化表MOT管理部分详细介绍了内存优化表，这是一种与标准存储引擎并排嵌入的高性能存储引擎…</p>
                            </div>
                            <div class="stips">所属分类：VASTG100-高可用-性能；VASTE100-高可用-性能…</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="param2.pageNo"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="param2.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="result" v-if="act == 2">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">服务请求（19）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">监控主机上的agent目录<span>数据</span>是否会自动清理</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="param2.pageNo"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="param2.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="result" v-if="act == 3">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">海量资讯（19）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="param2.pageNo"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="param2.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//图标
import { Back , Search  } from '@element-plus/icons-vue'
  export default {
      components: {
        Back, Search
    },
    data() {
      return {
          listl1:['全部','知识库文档（89）','服务请求（19）','海量资讯（19）'],
          act: 0,
          state1: '',
          param2: {
            docType: '',
            engineerFlag: false,
            pageNo: 1,
            pageSize: 5,
            threeCategoryId: '',
        },
        total: 50,
      };
    },
    mounted(){
        
    },
    methods: {
        OnClick(index){
            this.act = index;
            console.log(this.act);
        },

        handleSizeChange (val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange (val) {
            console.log(`当前页: ${val}`);
        },
    }
  };
</script>

<style lang="scss" scoped>
.navmb{
     .icon{
         margin-right: 10px;
     }
}
.m-searchl{
    overflow: hidden;
    background: #fff;
    padding: 20px;
    .search{
        overflow: hidden;
        margin-bottom: 20px;
    }
    .history{
        overflow: hidden;
        .ll{
            float: left;
            font-size: 12px;
            color: #999999;
            line-height: 30px;
            margin-right: 15px;
        }
        .rr{
            overflow: hidden;
            span{
                display: block;
                float: left;
                min-width: 82px;
                height: 30px;
                line-height: 30px;
                background: #F0F0F0;
                text-align: center;
                font-size: 12px;
                color: #666666;
                margin-right: 20px;
                padding: 0 15px;
            }
        }
    }
}
.m-resultl{
    overflow: hidden;
    background-color: #fff;
    padding: 20px 30px;
    .tips{
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .result{
        overflow: hidden;
        .item{
            overflow: hidden;
            margin-bottom: 19px;
        }
    }
}
.ul-listl1{
    font-size: 0;
    margin-bottom: 30px;
    li{
        display: inline-block;
        margin-right: 10px;
        .con{
            display: block;
            width: 120px;
            height: 32px;
            line-height: 32px;
            background: #F7F7F7;
            text-align: center;
            display: block;
            font-size: 12px;
            color: #181819;
            transition: .5s;
            cursor: pointer;
        }
        .con:hover{
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }
    li.on{
        .con{
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }
}
.m-titl1{
    overflow: hidden;
    padding-bottom: 11px;
    border-bottom: 1px solid #E8E9EB;
    margin-bottom: 26px;
    .more{
        float: right;
        font-size: 12px;
        color: #ff6a00;
        line-height: 1.416em;
        transition: .5s;
    }
    .more:hover{
        color: #f83;
    }
    .tit{
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
    }
}
.m-titl1.styl2{
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 10px;
}
.ul-listl2{
    overflow: hidden;
    li{
        margin-bottom: 21px;
        overflow: hidden;
        .con{
            display: block;
            overflow: hidden;
            .rr{
                float: right;
                max-width: 350px;
                text-overflow:ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #9c9c9c;
                margin-left: 20px;
            }
            .tit{
                text-overflow:ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #151822;
                span{
                    color: #ff6a00;
                }
                transition: .5s;
            }
        }
        .con:hover{
            .tit{
                color: #ff6a00;
            }
        }
    }
}
.ul-listl2.styl2{
    margin-bottom: 57px;
    li{
        margin-bottom: 0;
        .con{
            padding: 30px 0 24px;
            border-bottom: 1px dashed #E6E6E6;
        }
    }
}
.ul-listl3{
    overflow: hidden;
    margin-bottom: 40px;
    .con{
        display: block;
        overflow: hidden;
        padding: 20px 0 17px;
        border-bottom: 1px dashed #E6E6E6;
        .tit{
            font-size: 14px;
            line-height: 1.583em;
            color: #151822;
            margin-bottom: 10px;
            transition: .5s;
            span{
                color: #ff6a00;
            }
        }
        .desc{
            font-size: 12px;
            color: #686868;
            line-height: 1.666em;
            overflow: hidden;
            margin-bottom: 10px;
        }
        .stips{
            font-size: 12px;
            line-height: 2.333em;
            overflow: hidden;    
            color: #9c9c9c;
        }
    }
    .con:hover{
        .tit{
            color: #ff6a00;
        }
    }
}
.m-pages{
    overflow: hidden;
    text-align: center;
    font-size: 0;
    margin-bottom: 10px;
    .pages{
        display: inline-block;
        vertical-align: middle;
    }
}
</style>